/* ==========================================================================
  Overrides to Bootstrap
   ========================================================================== */

$font-family-sans-serif: "Ubuntu", Arial, sans-serif;

$alert-danger-bg: $red;
$alert-danger-text: #fff;
$alert-danger-border: darken($red, 10%);

$brand-primary: $blue;
$brand-success: $green;
$link-color: $radiant-blue;

$text-color: $gray-dark-mid;
$headings-color: $gray-dark;

$font-size-h1:            26px;
$font-size-h2:            20px;
$font-size-h3:            16px;
$font-size-h4:            14px;

$font-size-small:         13px;
$line-height-small:       1.5;
$padding-small-vertical:  3px;
$padding-small-horizontal:  5px;

// Default panel overrides
$panel-default-text: $dark-blue;
$panel-default-border: $gray-lightest-border;
$panel-default-heading-bg: $gray-lightest;
$panel-bg: $gray-lightest;

// Wells
$well-bg: $gray-lightest;
$well-border: $gray-lightest-border;

// Dropdowns
$dropdown-header-color: $gray-mid;

// Headings
$headings-font-weight: 500;

//** Small `.form-control` height

$text-muted: $gray-mid;

//** Number of columns in the grid.
$grid-columns: 16;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;

// Huge screen / very wide desktop
$screen-hg: 1440px !default;
$screen-hg-min: $screen-hg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-hg-desktop: $screen-hg-min !default;

$table-bg-accent: $gray-lightest;
$table-bg-hover: $yellow-faded;

$table-condensed-cell-padding:  4px;

// For planner
$hour-height: 30px;

/* ==========================================================================
  Overrides to custom components
   ========================================================================== */

