/* ==========================================================================
  c-side-menu
   ========================================================================== */

/* Variables
   ========================================================================== */

$side-menu-width: 200px !default;
$side-menu-width-margin: $grid-gutter-width !default;
$side-menu-orb-dimensions: 6px !default;
$side-menu-orb-background-color: #B2B2B2 !default;
$side-menu-icon-horizontal-padding: 20px !default;
$side-menu-icon-color: #035373 !default;
$side-menu-item-background-color: $gray-lightest !default;
$side-menu-item-height: 44px !default;
$side-menu-item-hover-background-color: #fff !default;
$side-menu-active-item-border-color: $brand-primary !default;
$side-menu-item-text-color: #4A4A4A !default;
$side-menu-border-color: #D5D5D5 !default;
$side-menu-border-radius: 4px !default;

/* Component
   ========================================================================== */

.c-side-menu {
  float: left;
  width: $side-menu-width;
  padding: 0;
  list-style-type: none;
  border: 1px solid $side-menu-border-color;
  border-radius: $side-menu-border-radius;
  color: $side-menu-item-text-color;
}

.c-side-menu-item {
  position: relative;
  background-color: $side-menu-item-background-color;
  border-bottom: 1px solid $side-menu-border-color;

  &:first-child {
    border-top-left-radius: $side-menu-border-radius;
    border-top-right-radius: $side-menu-border-radius;
  }

  &:last-child {
    border-bottom-left-radius: $side-menu-border-radius;
    border-bottom-right-radius: $side-menu-border-radius;
    border-bottom: 0;
  }

  &:hover {
    background-color: $side-menu-item-hover-background-color;
  }

  a {
    display: block;
    line-height: $side-menu-item-height;
    color: inherit;
    text-decoration: none;
  }
}

.c-side-menu-item-is-active {
  background-color: $side-menu-item-hover-background-color;

  &:after {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    bottom: 0;
    width: 5px;
    background-color: $side-menu-active-item-border-color;
  }

  &:first-child {
    &:after {
      border-top-left-radius: $side-menu-border-radius;
    }
  }

  &:last-child {
    &:after {
      border-bottom-left-radius: $side-menu-border-radius;
    }
  }
}

.c-side-menu-item-icon-container {
  width: $side-menu-orb-dimensions + ($side-menu-icon-horizontal-padding * 2);
  height: $side-menu-item-height;
  float: left;
  position: relative;
  text-align: center;

  .c-icon-business-object {
    position: relative;
    top: -2px;
    left: 4px;
  }
}

.c-icon-business-object-image {
	display: block;
	padding-left: 12px;
}

.c-side-menu-item-orb {
  background-color: $side-menu-orb-background-color;
  width: $side-menu-orb-dimensions;
  height: $side-menu-orb-dimensions;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  margin-left: -($side-menu-orb-dimensions / 2);
  margin-top: -($side-menu-orb-dimensions / 2);
}

/* Helper component for positioning sidebar along fluid content
   ========================================================================== */

.c-content-with-side-menu {
  @include clearfix();
}

.c-content-with-side-menu-main-content {
  float: right;
  width: calc(100% - #{$side-menu-width} - #{$side-menu-width-margin});
}


/* Help within sidenar
   ========================================================================== */

.c-side-menu-item {
  position: relative; // This is a repeat, just repeating this because the next block of code supposes position: relative; on this element
  
  // Nested for specificity
  .c-side-menu-help-link {
    position: absolute;
    top: -1px;
    right: 12px;
  
    display: none;

    .js-help-active & {
      display: inline-block;
    }
  
  }

}
