/* ==========================================================================
  Colors
  Only define colors here.
    * Try to describe your color, for example soft-yellow, mid-blue
    * Don't use sass color functions, stick to hex values, or the colors
      component in styleguide won't regenerate automatically anymore
 ========================================================================== */

/* Grays
 ========================================================================== */

$gray-dark: #35464C;
$gray-dark-mid:  #637C86;
$gray-mid: #8C9FA7;
$gray-light: #DFE5E7;
$gray-light-border: #C5D3D9;
$gray-lighter: #E7EDEF;
$gray-lighter-border: #D1DBE0;
$gray-lightest: #F3F6F7;
$gray-lightest-border: #D5DFE2;
$white: #FFF;

/* Brand colors
 ========================================================================== */

$dark-blue: #035373;
$blue: #0080B3;
$radiant-blue: #2BB4EB;
$orange: #F6AB2D;
$purple: #85529B;
$green: #38BEA4;

/* Functional colors
 ========================================================================== */

$red: #DD5B5B;
$light-blue: #E9F7FC;
$yellow-faded: #FFFFE6;

/* Pastel colors
 ========================================================================== */

$blue-pastel: #DCECFA;
$orange-pastel: #F9EAD1;
$purple-pastel: #F3DEFD;
$green-pastel: #D7F6E5;
$red-pastel: #FDDFDF;
$yellow-pastel: #FDF7E0;
$pink-pastel: #FFEAF6;
$gray-pastel: #DFE5E7;