/* ==========================================================================
  c-width-limiter
   ========================================================================== */

/* Variables 
   ========================================================================== */

$c-width-limiter-width:        50em !default;

/* Component
   ========================================================================== */

.c-width-limiter {
    p {
      max-width: $c-width-limiter-width;
    }
}