/* ==========================================================================
  Additions to bootstrap/grid
   ========================================================================== */

/* Space out grid gutter evenly with side gutter
   ========================================================================== */

@media (min-width: $screen-sm) {
  .grid-space-rows-evenly .row {
    > [class^="col-"]:first-child,
    > [class*="col-"]:first-child {
     padding-right: $grid-gutter-width / 4;
     padding-left: $grid-gutter-width / 2;
    }
    > [class^="col-"]:last-child,
    > [class*="col-"]:last-child {
     padding-right: $grid-gutter-width / 2;
     padding-left: $grid-gutter-width / 4;
    }
  }
}

/* Start using horizontal scroll instead of reflowing page below 1024px
   ========================================================================== */

@media (max-width: 1024px) {
  .bc-container {
    width: 1024px;
  }
}

// Huge grid
//
// Columns, offsets, pushes, and pulls for the huge desktop device range.

@media (min-width: $screen-hg-min) {
  @include make-grid(hg);
}
