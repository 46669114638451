/* Planner structure
   ========================================================================== */

.c-planner-structure {
  height: 100%;
}

.c-planner-structure-left {
  background: $gray-lighter;
  float: left;
  width: 240px;
  padding: 0 15px;
  height: 100%;
  overflow: scroll;
  border-right: 1px solid $gray-light;
  box-shadow: 0 0 4px 0 rgba(#000,0.2);
}

.c-planner-structure-right {
  float: right;
  width: calc(100% - 260px);
  height: 100%;
  overflow: scroll;
}