/* PROGRESS BAR */
.rich-progress-bar-completed {
	background-color: #337ab7;
}
.rich-progress-bar-height {
	height: 20px;
}
.rich-progress-bar-width {
	width: 100%;
}
.rich-progress-bar-block {
	white-space: nowrap;
}
.rich-progress-bar-uploaded {
	background-repeat: repeat-x;
}
.rich-progress-bar-uploaded-dig {
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
}
.rich-progress-bar-shell {
	margin-bottom: 2px;
}
.rich-progress-bar-shell-dig {
	margin-bottom: 2px;
	overflow: hidden;
	position: relative;
}
.rich-progress-bar-padding {
	padding: 0;
}
.rich-progress-bar-uploaded {
	background-color: #337ab7;
}