/* ==========================================================================
  Additions to bootstrap/dropdowns
   ========================================================================== */

/* Spacing tweak
   ========================================================================== */

.dropdown-toggle .caret {
  margin-left: 3px;
}

/* Checkboxes in dropdowns
   ========================================================================== */

.dropdown-menu {
  li {
    & > .checkbox {
      margin: 0;
      padding: 0 5px;

      label {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
}

/* Icons in dropdown menu
   ========================================================================== */

.dropdown-menu > li > .dropdown-menu-item-has-icon {
  padding-left: 20px;

  .glyphicon {
    margin-right: 5px;
    font-size: 16px;
    position: relative;
    top: 3px;
  }
}

/* Full width dropdown menu
   ========================================================================== */

.dropdown-block {
  .btn {
    display: block;
    position: relative;
    text-align: left;
    width: 100%;

    .caret {
      position: absolute;
      right: 10px;
      top: 14px;
    }
  }

  .dropdown-menu {
    width: 100%;
  }
}

/* Dropdown submenu
   ========================================================================== */

$dropdown-submenu-width: 340px;

/* Remove borders from regular Bootstrap dropdown menu in favor of inset shadows
   Reason: if we hover over the 1px border that is not a link we would not get the child dropdown
   ========================================================================== */

.dropdown-menu {
  border: none;
  box-shadow: inset 0 0 0 1px #CCC, 0 6px 12px rgba(0,0,0,.175);
  a {
    box-shadow: inset -1px 0 0 0 #CCC, inset 1px 0 0 0 #CCC;
  }
}

/* Override base width depending on where the dropdown originates from
   ========================================================================== */

.bc-navbar-medium.bc-navbar-gray-lighter .dropdown-menu {
  min-width: $dropdown-submenu-width;
}

.dropdown-submenu {
  position: relative;
  width: $dropdown-submenu-width;
  .dropdown-menu {
    width: $dropdown-submenu-width;
  }
}

.dropdown-menu-scroll {
  width: $dropdown-submenu-width;
  max-height: 400px;
  overflow: scroll;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu.pull-right > .dropdown-menu {
  left: -$dropdown-submenu-width - 1px;
  float: right;
}

.dropdown-submenu.pull-right .dropdown-menu,
.dropdown-submenu.pull-right .dropdown-submenu {
  float: right !important;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-left-color: $gray;
  margin-top: 6px;
  margin-right: -10px;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
