/* ==========================================================================
  bc-bg
  Background colors
   ========================================================================== */

.bc-bg-gray-light {
  background: $gray-light;
}

.bc-bg-gray-lighter {
  background: $gray-lighter;
}

.bc-bg-gray-lightest {
  background: $gray-lightest;
}

.bc-bg-gray-custom {
  background: #000;
}