/* ==========================================================================
  Additions to bootstrap/popovers
   ========================================================================== */

.popover {
  max-width: 400px;
}

.popover-header {
  border-bottom: 1px solid $gray-lighter-border;
}

.popover-footer {
  border-top: 1px solid $gray-lighter-border;
}

.popover-content {
  padding: 0;
  .popover-content {
    padding: 14px;
    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}