/* ==========================================================================
  bc-progress-bar
   ========================================================================== */

/* Variables
   ========================================================================== */

$bc-progress-bar-width: 120px !default;
$bc-progress-bar-height: 4px !default;
$bc-progress-bar-background-color: $gray-light !default;
$bc-progress-bar-value-background-color: rgba($gray, .6) !default;
$bc-progress-bar-complete-value-background-color: $brand-success !default;
$bc-progress-bar-complete-label-color: $brand-success !default;
$bc-progress-bar-empty-value-background-color: lighten($brand-danger, 25%) !default;
$bc-progress-bar-empty-label-color: $brand-danger !default;

/* Component
   ========================================================================== */

.bc-progress-bar-complete {
  .bc-progress-bar-container-value {
    background-color: $bc-progress-bar-complete-value-background-color;
  }
  .bc-progress-bar-label {
    color: $bc-progress-bar-complete-label-color;
  }
}

.bc-progress-bar-generic {
  .bc-progress-bar-container-value {
    background-color: $brand-primary;
  }
}

.bc-progress-bar-empty {
  .bc-progress-bar-container-background {
    background-color: $bc-progress-bar-empty-value-background-color;
  }
  .bc-progress-bar-label {
    color: $bc-progress-bar-empty-label-color;
  }
}

.bc-progress-bar-container {
  width: $bc-progress-bar-width;
  height: $bc-progress-bar-height;
  position: relative;
}

.bc-progress-bar-container-auto {
  width: auto;
}

.bc-progress-bar-container-background {
  background-color: $bc-progress-bar-background-color;
  width: 100%;
  height: 100%;
}

.bc-progress-bar-container-value {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: $bc-progress-bar-value-background-color;
}

.bc-progress-bar-label {
  font-size: 12px;
}
