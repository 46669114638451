/* ==========================================================================
  Inline help
   ========================================================================== */

.js-help-active {

  span[data-toggle="tooltip"] {
    color: $orange;
    &:after {
      background: url(../images/help.svg) no-repeat right center;
      content: " ";
      display: inline-block;
      width: 16px;
      height: 16px;
      position: relative;
      top: 2px;
    }
  }

  .panel-title span[data-toggle="tooltip"] {
    padding: 4px 6px;
    color: $orange;
    position: relative;
    right: 6px;
    &:link,
    &:visited {
      text-decoration: none;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $gray-light;
      border-radius: 2px;
      transition: 0.18s background-color ease-in-out;
    }
  }

}

