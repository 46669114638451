/* Password strength meter
   ========================================================================== */

.bc-password-strength-meter {
  font-size: 12px;
  .bc-password-strength-level {
    &.strong {
        color: $brand-success;
    }
    &.weak {
        color: $brand-danger;
    }
  }
}