/* ==========================================================================
  bc-recent-items
   ========================================================================== */

.bc-recent-items > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  @include clearfix;
}

// Extra styles for dropdown toggle in this context
.bc-recent-items {
  .dropdown-toggle {
    color: #FFF;
    font-size: 12px;
    padding: 7px 3px;
    display: inline-block;
    margin-left: 5px;
    &:focus {
      background: rgba(#000,0.2);
    }
  }
  
  .dropdown-menu.pull-right {
    right: -30px;
  }
}

.bc-recent-items > ul > li {
  float: left;
}

.bc-recent-items-item-desc {
  padding-top: 7px;
  color: #BDF1FF;
  font-weight: $headings-font-weight;
  font-size: 12px;
}

.bc-recent-items-item {
  float: left;
  list-style: none;
  padding-right: 1px;
  margin-right: 1px;
  position: relative;
  &:after {
    content: " ";
    width: 1px;
    height: 12px;
    background: rgba(#FFF,0.5);
    position: absolute;
    top: 10px;
    right: 0;
  }
}

.bc-recent-items-item > a {
  display: block;
  color: #fff;
  padding: 7px;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }
  &:focus {
    background: rgba(#000,0.2);
  }
}
