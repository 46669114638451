/* ==========================================================================
  bc-toolbar
   ========================================================================== */

/* Variables
   ========================================================================== */

$toolbar-small-height: 32px;
$toolbar-regular-height: 50px;
$toolbar-large-height: 72px;

/* Reset padding in common elements
   ========================================================================== */

.panel-has-bc-toolbar > .panel-heading {
  padding: 0;
}

.modal-has-bc-toolbar {
  .modal-header,
  .modal-footer {
    padding: 0;
  }
}

.alert-has-bc-toolbar {
  padding: 0;
}

/* Component
   ========================================================================== */

.bc-toolbar {
  width: 100%;
  height: $toolbar-regular-height;
  display: flex;
  align-items: center;
  
  .panel &,
  .popover &,
  .modal &,
  .alert & {
    padding: 0 ($grid-gutter-width / 2);
  }

  button {
    margin-top: 0;
  }
}

/* Heights
   ========================================================================== */

.bc-toolbar-small {
  height: $toolbar-small-height;
}

.bc-toolbar-large {
  height: $toolbar-large-height;
}

/* Divider
   ========================================================================== */

.bc-toolbar-divider {
  background: #DDD;
  width: 1px;
  margin: 0 15px;
  height: 100%;
  line-height: $toolbar-regular-height;

  .bc-navbar-large & {
    height: $bc-navbar-large-height;
  }
}

/* Standard titles and text
   ========================================================================== */

.bc-toolbar-title {
  white-space: nowrap;
  font-size: 16px;
  margin: 0;
  padding: 0 !important;
}

.bc-toolbar-title-small {
  font-size: 14px;
  margin: 0;
}

.bc-toolbar-text {
  font-size: 14px;
  margin: 0;
}

.bc-toolbar-title,
.bc-toolbar-title-small,
.bc-toolbar-text {
  line-height: $toolbar-regular-height;

  .bc-toolbar-small & {
    line-height: $toolbar-small-height;
  }

  .bc-toolbar-large & {
    line-height: $toolbar-small-height;
  }
}

/* Parts alignment
   A toolbar can have a left, right and center part
   ========================================================================== */

.bc-toolbar-left {
  justify-content: flex-start;
}

.bc-toolbar-right {
  justify-content: flex-end;
}

.bc-toolbar-center {
  position: absolute;
  left: 50%;
}

.bc-toolbar-center-inner {
  margin-left: -50%;
}

/* Truncated elements
   Using this modifier class, a toolbar element will truncate content inside of it with an ellipsis.
   ========================================================================== */

.bc-toolbar-truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Button groups
   If you have multiple buttons in one toolbar element, group them together with this modifier class
   to control the spacing.
   ========================================================================== */

.bc-toolbar-btn-group {
  display: flex;
  flex-wrap: nowrap;

  .btn {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

/* Component alignment
   Within the toolbar-left, toolbar-center or toolbar-right part, there are toolbar-item divs,
  these are flexed to position the elements
   ========================================================================== */

.bc-toolbar-left,
.bc-toolbar-right,
.bc-toolbar-center-inner {
  flex: 1;
  display: flex;
  align-items: center;

  &.bc-toolbar-shrink {
    flex: 0 auto;
  }
}

.bc-toolbar-element {
  .bc-toolbar-left & {
    padding: 0;
    padding-right: ($grid-gutter-width / 2);
  }

  .bc-toolbar-right & {
    padding: 0;
    padding-left: ($grid-gutter-width / 2);
  }
}

/* Bottom toolbar (mobile)
   ========================================================================== */

.bc-toolbar-bottom {
  background-color: #FFF;
  border-top: 1px solid $gray-light;
  padding: 9px;
}