/* ==========================================================================
  bc-task-popover
   ========================================================================== */

/* Component
   ========================================================================== */

.bc-task-popover {
  background-color: $gray-lightest;
  border: 1px solid $gray-lightest-border;
  border-bottom: 0;
  bottom: 0;
  box-shadow: 0 2px 15px rgba(0,0,0,0.2);
  border-radius: 4px 4px 0 0;
  position: fixed;
  transition: transform 0.15s cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.15s, width 0.15s cubic-bezier(0.895, 0.030, 0.685, 0.220);
  width: 480px;
}

.bc-task-popover.is-collapsed {
  transition: transform 0.15s cubic-bezier(0.895, 0.030, 0.685, 0.220), width 0.15s cubic-bezier(0.165, 0.840, 0.440, 1.000) 0.15s;
  transform: translateY(calc(100% - 34px));
  width: 200px;

  .bc-task-popover-content {

  }
}

.bc-task-popover-header {
  align-items: center;
  background-color: $brand-primary;
  border-radius: 4px 4px 0 0;
  color: #FFF;
  display: flex;
  height: 34px;
  margin: -1px -1px 0 -1px;
  padding: 0 10px;

  .btn-link {
    color: #FFF;
  }
}

.bc-task-popover-title,
.bc-task-popover-title:hover,
.bc-task-popover-title:focus {
  color: #FFF;
  font-weight: bold;
}

.bc-task-popover-subtitle {
  color: rgba(#FFF, 0.6);
  margin-left: 5px;
}

.bc-task-popover-controls {
  margin-left: auto;
}

.bc-task-popover-content {
  max-height: 300px;
  overflow-y: auto;
}

.bc-task-popover-row {
  align-items: center;
  color: $gray-dark;
  display: flex;
  font-size: 13px;
  padding: 8px 10px;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-lightest-border;
  }

  &:hover {
    background-color: #FFF;
  }
}

.bc-task-popover-row-timing {
  padding: 0 10px;
  width: 70px;
}

.bc-task-popover-row-meta {
  padding: 0 10px;
}

.bc-task-popover-row-title {
  color: $gray-dark;
  font-weight: bold;
}

.bc-task-popover-row-connection {
  color: $gray-dark-mid;
  font-size: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bc-task-popover-row-controls {
  flex: none;
  margin-left: auto;
  text-align: right;
  width: 110px;
}

.bc-task-popover-row-stop {
  visibility: hidden;

  .bc-task-popover-row:hover & {
    visibility: visible;
  }
}
