/* signature pad */
.signature-pad {
  position: relative;
  width: 100%;
  height: 100%;
}

.signature-pad-body {
  border: 1px solid #f4f4f4;
  margin-bottom: 60px;
}

.signature-pad-body canvas {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
    height: 300px;
}

.signature-pad-footer {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  height: 40px;
}

.signature-pad-footer
  .description {
    color: #C3C3C3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
  }

.signature-pad-footer
  .button {
    position: absolute;
    bottom: 0;
  }

.signature-pad-footer
  .button.clear {
    left: 0;
  }

.signature-pad-footer
  .button.save {
    right: 0;
  }

@media screen and (max-width: 1024px) {
  .signature-pad {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    min-width: 250px;
    min-height: 140px;
    margin: 5%;
  }
  #github {
    display: none;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .signature-pad {
    margin: 10%;
  }
}

@media screen and (max-height: 320px) {
  .signature-pad-body {
    left: 0;
    right: 0;
    top: 0;
    bottom: 32px;
  }
  .signature-pad-footer {
    left: 20px;
    right: 20px;
    bottom: 4px;
    height: 28px;
  }
  .signature-pad-footer
    .description {
      font-size: 1em;
      margin-top: 1em;
    }
}