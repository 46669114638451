/* ==========================================================================
  Content styling
   ========================================================================== */

.c-content {
  h3:first-child {
    margin-top: 0;
  }
  a {
    text-decoration: underline;
  }
  p, ul {
    max-width: 50em;
  }
}