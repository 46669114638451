/* ==========================================================================
  c-video-wrapper
   ========================================================================== */

// Wrapper for iframes like YouTube
.c-video-wrapper {
  position: relative;
  padding-bottom: 43%;
  height: 0;
  margin-bottom: 1.2em;
  &.c-video-wrapper-aspect-2-3 {
    padding-bottom: 66.67%; /* 3:2 */
  }
  &.c-video-wrapper-aspect-16-9 {
    padding-bottom: 56.25%; /* 16:9 */
  }
}

.c-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}