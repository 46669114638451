
/* EXTENDED DATATABLES STYLE OVERRIDE - MAKE SURE HEIGHT IS SET AUTOMATICALLY */
.rich-extdt-maindiv {
	height: auto !important;
}
.rich-extdt-maindiv, .extdt-innerdiv {
	position: relative !important;
}
.extdt-outerdiv {
	height: auto !important;
	overflow: visible !important;
}
.extdt-content {
	height: auto !important;
}
/* EXTENDED DATATABLE COLUMN RESIZE */
.extdt-hsep
{
	background-position: center;
	background-repeat: repeat-y;
	cursor: e-resize;
	display: block;
	font-size: 1px;
	height: 13px;
	overflow: hidden;
	position: absolute;
	top: 0;
	white-space: nowrap;
	width: 6px;
	z-index: 60;
}
.extdt-hsep {
	border-right: solid 1px #dddddd;
}
.extdt-hsplit
{
	border-right: 1px dashed;
	cursor: e-resize;
	width: 1px;
	z-index: 100;
}
/* EXTENDED DATATABLE DRAG AND DROP */
.extdt-hdrop {
    cursor: pointer;
    font-size: 1px;
    width: 10px;
    height: 13px;
    top: 0;
    position: absolute;
    display: block;
    white-space: nowrap;
    z-index: 61;
}
.extdt-hdrop-top
{
	background-repeat: no-repeat;
	display: block;
	height: 50%;
	position: relative;
	top: -9px;
	width: 100%;
}
.extdt-hdrop-top-left
{
	background-position: top left;
	left: -5px;
}
.extdt-hdrop-top-right
{
	background-position: top right;
	right: -4px;
}
.extdt-hdrop-bottom
{
	background-repeat: no-repeat;
	display: block;
	height: 50%;
	position: relative;
	top: 9px;
	width: 100%;
}
.extdt-hdrop-bottom-left
{
	background-position: bottom left;
	left: -5px;
}
.extdt-hdrop-bottom-right
{
	background-position: bottom right;
	right: -4px;
}
.extdt-hdrop-top
{
	background-image: url("../images/col-move-top.gif");
}
.extdt-hdrop-bottom
{
	background-image: url("../images/col-move-bottom.gif");
}

/* Make sure there is enough padding for header cell with regard to resize */
.extdt-headercell
{
	border-bottom: solid 0;
	border-right: solid 0;
	padding: 4px 4px 4px 4px;
	text-align: center;
}