/* ==========================================================================
   bc-navbar
   Navigation bar that can be:
    * Static, containing controls
    * Fixed, with a scrollable area below it (in combination with .bc-scroll-context)
   ========================================================================== */

/* Variables
   ========================================================================== */

$bc-navbar-small-height:                          32px !default;
$bc-navbar-height:                                49px !default;
$bc-navbar-large-height:                          78px !default;
$bc-navbar-divider-border-color:                  $gray-light !default;
$bc-navbar-btn-stretch-hover-bg:                  $gray-light !default;
$bc-navbar-shadow-strength:                       0.3 !default;
$bc-navbar-shadow-bg-color:                       $gray-base !default;

/* Mixins
   ========================================================================== */

@mixin bc-navbar-variant($bg,$border) {
  background-color: $bg;
  border-color: $border;
  .bc-toolbar-element-bordered {
    border-color: $border;
  }
}

/* ==========================================================================
   Navbars
   ========================================================================== */

.bc-navbar {
  position: relative;
  min-height: $bc-navbar-height; // Ensure a navbar always shows (e.g., without a .bc-navbar-brand in collapsed mode)

  // Prevent floats from breaking the navbar
  @include clearfix();
}

.bc-navbar-small {
  min-height: $bc-navbar-small-height;
}

.bc-navbar-medium {
  min-height: $bc-navbar-height;
}

.bc-navbar-large {
  min-height: $bc-navbar-large-height;
}

.bc-navbar-padded {
  padding: 20px 0;
}

.bc-navbar-fixed-top,
.bc-navbar-fixed-bottom {
  position: absolute;
  width: 100%;
}

.bc-navbar-fixed-top {
  top: 0;
}

.bc-navbar-fixed-bottom {
  bottom: 0;
}

.bc-navbar-top {
  border-bottom: 1px solid $gray-light;
}

.bc-navbar-bottom {
  border-top: 1px solid $gray-light;
}

/* Navbar variants
  @mixin bc-navbar-variant($bg,$border)
   ========================================================================== */

.bc-navbar-white {
  @include bc-navbar-variant($white,darken($gray-light, 10%));
}

.bc-navbar-gray-lighter {
  @include bc-navbar-variant($gray-lighter,$gray-light);
}

.bc-navbar-gray-light {
  @include bc-navbar-variant($gray-light,darken($gray-light, 10%));
}

.bc-navbar-blue {
  @include bc-navbar-variant($blue,darken($blue, 5%));
  .bc-toolbar-text,
  .bc-toolbar-title {
    color: #FFF;
  }
}
