.select2-selection--multiple {
  box-sizing: border-box;

  cursor: pointer;
  display: block;

  min-height: 32px;

  user-select: none;
  -webkit-user-select: none;

  .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.select2-search--inline {
  float: left;

  .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
}
