/* ==========================================================================
  bc-top-controls
   ========================================================================== */

.bc-top-controls {
  background-color: $gray-light;
  padding: 20px 0;
  margin-bottom: 20px;
  display: block;

  .btn-link {
    .glyphicon-cross {
      color: rgba($gray, 0.5);
    }
  }
}

.bc-top-controls-header {
  display: flex;
  margin-bottom: 30px;
}

.bc-top-controls-actions {
  text-align: right;
  margin-right: -15px;
}

.bc-top-controls-title {
  flex: 1;
  color: $dark-blue;
  font-size: 18px;
}
