/* ==========================================================================
  bc-document-preview
   ========================================================================== */

.bc-document-preview {
  background-color: $gray-light;
  width: 100%;
  padding: 20px;
  padding-top: 40px;
  position: relative;
  display: flex;
}

.bc-document-preview-hint {
  position: absolute;
  top: 10px;
  text-transform: uppercase;
  font-size: $font-size-small;
  opacity: 0.5;
}

.bc-document-preview-file {
  background-color: #FFF;
  flex: 1;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(#000, 0.25);
}