/* ==========================================================================
  c-brand-logo
   ========================================================================== */

.c-brand-logo-size-medium {
  width: 170px;
  margin: 0 auto;
  display: block;
}

.bc-navbar .c-brand-logo {
  position: relative;
}
