/* ==========================================================================
  Block list
   ========================================================================== */

$block-list-item-bg:             #FFF !default;
$block-list-item-border-color:   $gray-light !default;
$block-list-item-hover-color:    #FFF !default;
$block-list-item-active-color:   #FFF !default;
$block-list-padding:             $grid-gutter-width / 2 $grid-gutter-width / 2 !default;
$block-list-highlight-color:     #FFC !default;
$block-list-padding-small:       $grid-gutter-width / 4 $grid-gutter-width / 2 !default;

/* Block list item
   ========================================================================== */

.bc-block-list { // ul or ol
  margin: 0;
  padding: 0;
}

/* Colors
   ========================================================================== */

.bc-block-list-item-info-text {
  color: $gray;
}

.bc-block-list-item-info-text-big,
.bc-block-list-item-info-text-bold {
  color: $gray-dark;
}

.bc-block-list-item-info-text-mini {
  color: $gray-mid;
}

/* Block list item
   ========================================================================== */

.bc-block-list-item {
  border-bottom: 1px solid $block-list-item-border-color;
  background: $block-list-item-bg;
  display: flex;
  width: 100%;
}

.bc-block-list-item:last-child {
  border-bottom: 0;
}

/*
  Sometimes a block list item is a list item, with a contained <a>; sometimes it
  is directly an <a> element.We support both situations for interactive lists (go to a link)
  or non interactive lists.
*/

.bc-block-list-item {
  padding: $block-list-padding;
}

a.bc-block-list-item,
.bc-block-list-item a {
  // We are supposing the block-list item in an <a> element here
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    background: $block-list-item-hover-color;
  }
}

.bc-block-list-item-active {
  background: $block-list-item-active-color;
}

/*
  This class needs to be applied to list items that don't contain links to switch over the padding
  from the <a> element to the list element
*/

.bc-block-list-item-no-link {
  padding: $block-list-padding;
}

/* Block item new
   ========================================================================== */

.bc-block-list-item-new {
  background: $block-list-highlight-color;
}

/* Block item new
   ========================================================================== */

.bc-block-list-item-has-image {
  .c-image-holder {
    margin-right: 12px;
  }
}

/* Icon on the left side
   ========================================================================== */

.bc-block-list-item-icon {
  align-items: center;
  display: flex;
  flex: none;
  margin-right: 12px;
  width: auto;
}

/* Disclosure arrow on right side
   ========================================================================== */

.bc-block-list-item-disclosure {
  flex: none;
  margin: auto;
  color: $gray-dark;
  width: auto;
}

.bc-block-list-item-info {
  flex: 1 1 auto;
  align-items: center;
  display: flex;
  min-width: 0;
  .c-avatar {
    margin-right: 12px;
  }
}

/* Inner text
   ========================================================================== */

.bc-block-list-item-info-text-holder {
  max-width: 100%;
}

.bc-block-list-item-info-text-big {
  font-size: 15px;
  font-weight: 500;
}

.bc-block-list-item-info-text-medium {
  font-size: 13px;
  line-height: 21px;
}

.bc-block-list-item-info-text-mini {
  color: $gray-mid;
  font-size: 12px;
}

.bc-block-list-item-info-text-bold {
  font-weight: $headings-font-weight;
}

.bc-block-list-item-info-label {
  line-height: 1.3;
}

/* Block list - light
   ========================================================================== */

.bc-block-list-light {
  .bc-block-list-item {
    background: #FFF;
  }
  a.bc-block-list-item,
  .bc-block-list-item a {
    &:hover,
    &:active,
    &:focus {
      background: $gray-lighter;
    }
  }

  .bc-block-list-item-active {
    background: $gray-lighter;
  }
}

/* Block list - small
   ========================================================================== */

.bc-block-list-small {
  a.bc-block-list-item,
  .bc-block-list-item a {
    padding: $block-list-padding-small;
  }
}

/* Arrow list line
   ========================================================================== */

.bc-block-list-item-info-arrow-list {
  display: flex;
  max-width: 100%;
}

.bc-block-list-item-info-arrow-list-item {
  flex: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  line-height: 21px;
  color: $gray;
  &:not(:first-child):before {
    content: '→';
    color: $gray-mid;
    margin: 0 5px;
  }
  &:last-child {
    flex: 1 1 auto;
  }
}
