/* bc-form-flex
   Kind of a replacement for inline forms to flex elements so they actually take up full available width
   ========================================================================== */

.bc-form-flex {
  .flexbox & {
    display: flex;
    * {
      flex: 0 1 auto; // Default
    }
    .btn {
      flex: 1 0 auto; // Don't shrink
    }
    
    label {
      flex: 0 0 auto;
    }
  }

  .no-flexbox & {
    * {
      display: inline-block;
    }
    label {
      display: inline-block;
    }
    input {
      width: 190px;
    }
  }

  label {
    .form-horizontal & {
      margin: 0;
      padding: 0;
      line-height: $input-height-base;
    }
  }

  > *:first-child {
    margin-right: 4px;
    margin-left: 0;
  }
  > * {
    margin: 0 4px;
  }
  > *:last-child {
    margin-left: 4px;
    margin-right: 0;
  }
  
  .form-control-static {
    margin-top: 24px;
  }
}
