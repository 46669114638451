/* ==========================================================================
  Additions to bootstrap/panels
   ========================================================================== */

/* Panel controls
   ========================================================================== */

.panel-heading,
.panel-footer {
  @include clearfix;
}

.panel-title {
  font-size: 17px;
  font-weight: $headings-font-weight;
}

.panel-body-no-spacing {
  padding: 0;
}

/* Panel
   ========================================================================== */

.panel-body + .panel-body {
  border-top: 1px solid $panel-inner-border;
}

/* Block lists in panels
   ========================================================================== */

// Place a non-bordered `.bc-block-list` within a panel (not within a `.panel-body`) and
// watch it go full width.

.panel {
  > .bc-block-list {
    margin-bottom: 0;
  }
  .bc-block-list-item:first-child {
    border-top-left-radius: ($panel-border-radius - 1);
    border-top-right-radius: ($panel-border-radius - 1);
  }
  .bc-block-list-item:last-child {
    border-bottom-left-radius: ($panel-border-radius - 1);
    border-bottom-right-radius: ($panel-border-radius - 1);
  }
}

/* Default panel overrides
   ========================================================================== */

.panel-white {
  background: #FFF;
  @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
  .panel-heading {
    background: #FFF;
  }
  .panel-footer {
    background: #FFF;
  }
}

/* Collapsable panels
   ========================================================================== */

.panel-heading a[data-toggle="collapse"] {
  color: inherit;
  .glyphicon {
    color: $gray-mid;
    margin-right: 15px;
    transition: transform .2s ease-in-out;
  }
  &.collapsed .glyphicon {
    transform: rotate(-90deg);
  }
}

/* Tables in panels
   ========================================================================== */

.panel-body .table th:first-child,
.panel-body .table td:first-child {
  padding-left: 15px;
}

.panel-body .table th:last-child,
.panel-body .table td:last-child {
  padding-right: 15px;
}