/* Individual planning
   ========================================================================== */

.c-p-ip-scheme-holder {
  .c-p-ip-hours {
    width: 15%;
    float: left;
  }
  .c-p-ip-scheme {
    width: 85%;
    float: right;
  }
}

.c-p-ip-scheme-holder {
  overflow: scroll;
  height: 500px;
}


.c-p-ip-layer-scheme {
  height: 24*$hour-height;
  width: 100%;
  background:
       repeating-linear-gradient(
          transparent 0,
          transparent #{$hour-height - 1px},
          $gray-lighter $hour-height
      );
}


.c-p-ip-layer-grid-gridline {
  height: $hour-height;
  border-bottom: 1px solid $gray-lighter; 
}

.c-p-ip-hour {
  height: $hour-height;
  font-size: 11px;
  color: $gray-mid;
}

.c-p-ip-scheme {
  position: relative;
  .p-box {
    position: relative;
    right: 10px;
  }
}


/* Left hand side structure
   c-resource-holder
   ========================================================================== */

.c-p-resource-holder .p-box {
  margin: 7.5px 0;
}
