
/* bc-navbar-nav
   Exactly the same as bootstrap's .navbar-nav, just scoped to .bc-navbar-nav
   ========================================================================== */

// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.bc-navbar-nav {
  margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);

  > li > a {
    padding-top:    10px;
    padding-bottom: 10px;
    line-height: $line-height-computed;
  }

  float: left;
  margin: 0;

  > li {
    float: left;
    > a {
      padding-top:    $navbar-padding-vertical;
      padding-bottom: $navbar-padding-vertical;
    }
  }

}

// Alternate navbars
// --------------------------------------------------

// Default navbar

.bc-navbar-nav {
  > li > a {
    color: $navbar-default-link-color;

    &:hover,
    &:focus {
      color: $navbar-default-link-hover-color;
      background-color: $navbar-default-link-hover-bg;
    }
  }
  > .active > a {
    &,
    &:hover,
    &:focus {
      color: $navbar-default-link-active-color;
      background-color: $navbar-default-link-active-bg;
    }
  }
}

/* Inversed version
   ========================================================================== */

.bc-navbar-nav-inverse {
  > li > a {
    color: #FFF;
    transition: background 0.18s ease-in-out;
    &:hover,
    &:focus {
      color: #FFF;
      background: rgba(0,0,0,0.1);
    }
  }
  > .active > a {
    &,
    &:hover,
    &:focus {
      color: #FFF;
      background: rgba(0,0,0,0.1);
    }
  }
}

.bc-navbar-nav-inverse.nav .open > a,
.bc-navbar-nav-inverse.nav .open > a:hover,
.bc-navbar-nav-inverse.nav .open > a:focus {
  background: rgba(0,0,0,0.1);
}
