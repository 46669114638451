/* FILE UPLOAD */
.rich-fileupload-list-overflow {
	overflow: auto;
	overflow-x: hidden;
	overflow-y: auto;
}
.rich-fileupload-name {
	height: 58px;
	padding: 2px 10px 2px 10px;
	width: 100%;
}
.rich-fileupload-del {
	padding-bottom: 3px;
	padding-top: 3px;
	text-align: center;
	width: 90px;
}
.rich-fileupload-scroll {
	text-align: center;
	width: 10px;
}
.rich-fileupload-bold-label {
	font-weight: bold;
}
.rich-fileupload-button-content {
	padding: 2px 6px 2px 3px;
	text-align: left;
	white-space: nowrap;
	bottom: 2px;
}
.rich-fileupload-ico {
	background-position: 0 50%;
	background-repeat: no-repeat;
	padding-left: 19px;
	vertical-align: middle;
}
.rich-fileupload-button-dis {
	background-repeat: repeat-x;
	cursor: pointer;
	padding: 2px;
}
.rich-fileupload-button-content {
	display: block;
	text-decoration: none;
}
.rich-fileupload-ico-add {
	/*background-image: url('../images/upload/ico_add.gif');*/
	background-image: url('../images/glyphicons/plus@2x.png');
	background-size: 16px;
}
.rich-fileupload-ico-add-dis {
	background-image: url('../images/upload/ico_add_dis.gif');
}
.rich-fileupload-ico-start {
	background-image: url('../images/upload/ico_start.gif');
}
.rich-fileupload-ico-start-dis {
	background-image: url('../images/upload/ico_start_dis.gif');
}
.rich-fileupload-ico-stop {
	background-image: url('../images/upload/ico_stop.gif');
}
.rich-fileupload-ico-clear {
	background-image: url('../images/upload/ico_clear.gif');
}
.rich-fileupload-ico-clear-dis {
	background-image: url('../images/upload/ico_clear_dis.gif');
}