/* ==========================================================================
  Additions to bootstrap/forms
   ========================================================================== */

/* General
   ========================================================================== */

label {
  font-weight: 500;
}

.help-block {
  font-size: 13px;
}

/* Textarea sizes
   ========================================================================== */

textarea.form-control-height-s {
  height: 150px;
}

textarea.form-control-height-m {
  height: 200px;
}

textarea.form-control-height-l {
  height: 250px;
}

/* Block level labels
   ========================================================================== */

.form-group-block-labels {
  .checkbox,
  .radio {
    margin: 0;

    label {
      display: block;
      padding: 5px;
      padding-left: 30px;
      margin-left: -5px;
      border-radius: 2px;

      &:hover {
        background-color: $gray-lighter;
      }

      input[type="checkbox"],
      input[type="radio"] {
        margin-left: -20px;
      }
    }
  }
}

/* Disabled radio buttons and checkboxes
   ========================================================================== */

.radio-disabled,
.checkbox-disabled {
  color: $text-muted;
}

/* Button aligned with form group input
   ========================================================================== */

.btn-aligned-with-form-group-input {
  margin-top: 25px;
}

/* Read only inputs
   ========================================================================== */

input[readonly] {
  display: block;
  width: 100%;
  height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $input-color;
  border: 1px solid transparent;
}

/* Monetary values 
   ========================================================================== */

.monetary-value input,
.monetary-value {
  text-align: right;
  width: 100%;
}

/* Input group with icon 
   ========================================================================== */

.input-with-icon {
  position: relative;
  &.focus {
    .input-icon-text,
    .glyphicon {
      opacity: 1;
      color: $gray-dark;
    }
  }
  .input-icon-text,
  .glyphicon {
    position: absolute;
    left: 8px;
    top: 10px;
    z-index: 4;
    opacity: 0.5;
  }
  // Text e.g. €/¥/$ signs
  .input-icon-text {
    left: 10px;
  }
  .form-control {
    border-radius: $border-radius-base;
    padding-left: 24px;
  }
}

/* Widths for specific inputs
   ========================================================================== */

.form-control-date { // this class is also used for invoking datepickers
  width: 102px;
}

.form-control-time {
  width: 66px;
}

/* Widths for counting characters
   !important because this is at lowest level and we
   don't want to add other dependencies for CSS specifity
   ========================================================================== */

$form-control-basewidth: 36px;
$form-control-with-icon-basewidth: 46px;
$form-control-characterwidth: 8px;

.form-control-1char, .form-control-1chars  { width: $form-control-basewidth !important; }
.form-control-2chars { width: ($form-control-basewidth + 1 * $form-control-characterwidth) !important; }
.form-control-3chars { width: ($form-control-basewidth + 2 * $form-control-characterwidth) !important; }
.form-control-4chars { width: ($form-control-basewidth + 3 * $form-control-characterwidth) !important; }
.form-control-5chars { width: ($form-control-basewidth + 4 * $form-control-characterwidth) !important; }
.form-control-6chars { width: ($form-control-basewidth + 5 * $form-control-characterwidth) !important; }
.form-control-7chars { width: ($form-control-basewidth + 6 * $form-control-characterwidth) !important; }
.form-control-8chars { width: ($form-control-basewidth + 7 * $form-control-characterwidth) !important; }
.form-control-9chars { width: ($form-control-basewidth + 8 * $form-control-characterwidth) !important; }

.input-with-icon .form-control-1char, .input-with-icon .form-control-1chars  { width: $form-control-with-icon-basewidth !important; }
.input-with-icon .form-control-2chars { width: ($form-control-with-icon-basewidth + 1 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-3chars { width: ($form-control-with-icon-basewidth + 2 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-4chars { width: ($form-control-with-icon-basewidth + 3 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-5chars { width: ($form-control-with-icon-basewidth + 4 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-6chars { width: ($form-control-with-icon-basewidth + 5 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-7chars { width: ($form-control-with-icon-basewidth + 6 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-8chars { width: ($form-control-with-icon-basewidth + 7 * $form-control-characterwidth) !important; }
.input-with-icon .form-control-9chars { width: ($form-control-with-icon-basewidth + 8 * $form-control-characterwidth) !important; }

/* Inline form-control
   ========================================================================== */

.form-control-inline {
  min-width: 0;
  width: auto;
  display: inline;
}

/*
  FORMS necessary override because bootstrap puts height of multiple selects on auto, 
  but Richfaces adds size="1" for single selects
*/

select[size='1'].form-control {
	height: 34px;
}

select[size='1'].form-control.input-sm {
	height: 27px;
}

/* Form group min-height
   ========================================================================== */

.form-group {
  min-height: 58px;
}