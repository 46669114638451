.c-p-split-dropzone {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  text-align: center;
  font-size: 11px;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.5);

  .c-p-split-dropzone-left,
  .c-p-split-dropzone-right {
    align-items: center;
    width: 50%;
    display: flex;
    text-align: center;
    * {
      margin: 0 auto;
    }
  }

  .c-p-split-dropzone-left {
    background: rgba(#309A97, 0.4);
    color: #FFF;
  }

  .c-p-split-dropzone-right {
    background: rgba(#309A97, 0.6);
    color: #FFF;
  }

  /* business objects override */
  .c-side-menu-item-icon-container .c-icon-business-object {
    top: 10px;
  }
}