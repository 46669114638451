/* DATASCROLLER OVERRIDE - PAGINATION AS IN BOOTSTRAP*/
.rich-datascr td:first-child {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.rich-datascr td:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.rich-datascr-inact, .rich-datascr-act, .rich-datascr-button {
	position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857;
    text-decoration: none;
    color: #2BB4EB;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    cursor: pointer;
}
.rich-datascr-act {
	z-index: 2;
    color: #fff;
    background-color: #0080B3;
    border-color: #0080B3;
    cursor: default;
}

.rich-datascr td:empty {
   display: none;
}