/* bc-color-label
   A square color indication
   ========================================================================== */

.bc-color-label {
  height: 14px;
  width: 14px;
  display: inline-block;
  text-indent: -9000px;
  border-radius: 3px;
}

.bc-color-label-1 {
  background: $dark-blue;
}

.bc-color-label-2 {
  background: $orange;
}

.bc-color-label-3 {
  background: $purple;
}

.bc-color-label-4 {
  background: $green;
}

.bc-color-label-5 {
  background: $red;
}
