/* ==========================================================================
  Additions to bootstrap/modals
   ========================================================================== */

.modal-body + .modal-body {
  border-top: 1px solid $gray-light;
}

.modal-body-bg-alt {
  background: $gray-lighter;
}

.modal-title {
  font-size: $font-size-h3;
}

.modal-header,
.modal-footer,
.modal-body {
  &.collapsed {
    padding: 0;
  }
}

/* Centered modal
   ========================================================================== */

.centered-modal {
  text-align: center;

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }
}

@media screen and (min-width: 768px) {
  .centered-modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}

/* Nice alert modal variant
   ========================================================================== */

.modal-nice-alert {
  .modal-footer {
    padding: 10px 15px;

    &.collapsed {
      padding: 0;
    }
  }
}

.modal-nice-alert-title {
  color: $dark-blue;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Dual pane modal
   ========================================================================== */

.modal-body-dual-pane {
  @include clearfix();
  padding: 0;
  display: flex;

  .modal-body-pane {
    width: 50%;
  }
}

.modal-body-pane {
  float: left;

  &.modal-body-pane-padded {
    padding: 15px;
  }
}

/* Full screen modal
   Modal sizes are normally controlled on .modal-dialog stretched modal class has to be on .modal
   ========================================================================== */

.modal-stretch .modal-dialog {

  margin: $navbar-height/2 auto ;
  height: calc(100% - #{$navbar-height});

  .modal-content {
    height: 100%;
    position: relative;
  }

  .modal-header,
  .modal-footer {
    z-index: 2;
  }

  .modal-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .modal-body-holder {
    overflow: auto;
    position: absolute;
    top: $navbar-height + 1;
    bottom: $navbar-height + 1;
    left: 0;
    right: 0;
    padding: 0 15px;

    &.modal-pdf-container {
      &,
      .modal-body {
        padding: 0;
        overflow: hidden;
      }

      .modal-body,
      iframe {
        height: 100%;
        width: 100%;
      }

      iframe {
        border: 0;
      }
    }

    .modal-body {
      padding: 15px 0;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.modal-stretch-maximize-width .modal-dialog {
  width: calc(100% - #{$navbar-height});
  max-width: calc(100% - #{$navbar-height});
}


/* Full screen modal docked on the right side
   ========================================================================== */

.modal-dock-right {
  padding-right: 0 !important; // Override Bootstrap setting padding for overflowing modals (the modal does not overflow)
}

.modal-dock-right .modal-dialog {
  margin: 0;
  float: right;
  height: 100%;

  .modal-content {
    height: 100%;
    position: relative;
    border: none;
    border-radius: 0;
  }

  .modal-header,
  .modal-footer {
    z-index: 2;
  }

  .modal-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .modal-body-holder {
    overflow: auto;
    position: absolute;
    top: $navbar-height + 1;
    bottom: $navbar-height + 1;
    left: 0;
    right: 0;
    padding: 0 15px;

    .modal-body {
      padding: 15px 0;
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
