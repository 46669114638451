/* ==========================================================================
  Additions to bootstrap/table
   ========================================================================== */

/* Background
   ========================================================================== */

.table {
  thead {
    tr:first-child {
      th {
        border-bottom: 2px solid $gray-light;
      }
    }

    tr {
      th {
        border-bottom: 0;
      }
    }
  }
}

/* Tables with small fonts
   ========================================================================== */

.table.table-font-size-s {
  td, th {
    font-size: 13px;
  }
}

/* White cells
   ========================================================================== */

.table.table-cells-white {
  tbody {
    tr {
      td {
        background-color: #fff;
      }
    }
  }
}

/* Align middle
   ========================================================================== */

.table.table-align-middle {
  td {
    vertical-align: middle;
  }
}

/* Specific columns
   ========================================================================== */

.table-actions-column .btn-toolbar {
  float: right;
}

.table-actions-column .btn-toolbar-min-width-2-buttons {
  min-width: 74px;
}

.table-actions-column .btn-toolbar-min-width-4-xs-buttons {
  min-width: 120px;
}

/* No first border on first row
   ========================================================================== */

.table.table-no-first-border {
  tr:first-child td,
  tr:first-child th {
    border-top: none;
  }
}

/* Tables where the left side is the "label"
   ========================================================================== */

.table-left-labels {
  tbody th {
    width: 100px;
  }
}

.table-left-labels-medium {
  tbody th {
    width: 150px;
  }
}

.table-left-labels-long {
  tbody th {
    width: 200px;
  }
}

/* Table cell background colors
   ========================================================================== */

.table td.table-cell-color-1 {
  background-color: $red-pastel;
}

.table td.table-cell-color-2 {
  background-color: $orange-pastel;
}

.table td.table-cell-color-3 {
  background-color: $yellow-pastel;
}

.table td.table-cell-color-4 {
  background-color: $green-pastel;
}

.table td.table-cell-color-5 {
  background-color: $blue-pastel;
}

.table td.table-cell-color-6 {
  background-color: $purple-pastel;
}

.table td.table-cell-color-7 {
  background-color: $pink-pastel;
}

.table td.table-cell-color-8 {
  background-color: $gray-pastel;
}