/* ==========================================================================
  Additions to bootstrap/alerts
   ========================================================================== */

/* Alerts in panels
   ========================================================================== */

.modal-dialog .alert,
.panel .alert {
  border-radius: 0;
  margin: 0;
}

/* Floating alert
   ========================================================================== */

.alert-floating {
  max-width: 350px;
  min-width: 100px;
  display: inline-block;
  margin: 0;
  background-color: rgba($dark-blue, 0.8);
  color: #fff;
  border-color: transparent;

  .glyphicon {
    font-size: 18px;
  }
}

.alert-floating-container {
  position: fixed;
  top: 25px;
  left: 0;
  right: 0;
  z-index: 3000;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity .2s;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.alert-floating-inner {
  position: relative;
  p {
    margin: 0;
  }
  .btn {
    position: absolute;
    right: 0;
    top: -4px;
  }
}
