/* ==========================================================================
  bc-scroll-context 
   ========================================================================== */

/* Variables 
   ========================================================================== */

$bc-container-grid-gutter-width:              24px !default;
$bc-navbar-scroll-context-small-height:       32px !default;
$bc-navbar-scroll-context-height:             51px !default; // including borders
$bc-navbar-scroll-context-large-height:       70px !default;

/* Basic setup
   ========================================================================== */

.bc-scroll-context {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bc-scroll-context-content {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.bc-scroll-context > .bc-navbar-fixed-top {
  position: absolute;
  width: 100%;
  top: 0;
}

/* Content layouts
   ========================================================================== */

.bc-scroll-context-content-layout-default { // Default
  padding: $bc-container-grid-gutter-width 0;
  height: calc(100% - #{$bc-container-grid-gutter-width*2});
}

.bc-scroll-context-content-layout-fullscreen {
  padding: 0;
  height: 100%;
}

/* Calculate scrollable areas for different navbar layouts
   ========================================================================== */

// Calculations for navbar positions

.bc-scroll-context > .bc-navbar {
  top: 0; // Default
}

.bc-scroll-context > .bc-navbar + .bc-navbar {
  top: $bc-navbar-scroll-context-height;
}

.bc-scroll-context > .bc-navbar + .bc-navbar-small + .bc-navbar {
  top: $bc-navbar-scroll-context-height + $bc-navbar-scroll-context-small-height;
}

// Calculations for scrollable content area positions

// 1 medium bar
.bc-scroll-context >  .bc-navbar + .bc-scroll-context-content {
  top: $bc-navbar-scroll-context-height;
  height: calc(100% - #{$bc-navbar-scroll-context-height});
}

// 1 medium bar, 1 small bar
.bc-scroll-context > .bc-navbar + .bc-navbar-small + .bc-scroll-context-content {
  top: $bc-navbar-scroll-context-height + $bc-navbar-scroll-context-small-height;
  height: calc(100% - #{ $bc-navbar-scroll-context-height + $bc-navbar-scroll-context-small-height });
}

// 1 medium bar, 1 small bar, 1 medium bar
.bc-scroll-context > .bc-navbar + .bc-navbar-small + .bc-navbar + .bc-scroll-context-content {
  top: $bc-navbar-scroll-context-height + $bc-navbar-scroll-context-small-height + $bc-navbar-scroll-context-height;
  height: calc(100% - #{ $bc-navbar-scroll-context-height + $bc-navbar-scroll-context-small-height + $bc-navbar-scroll-context-height });
}

// Calculations for scrollable content area positions (with loading bar) 


/* Z-indexes 
   ========================================================================== */

.bc-scroll-context > .bc-navbar:nth-child(2) {
  z-index: 4;
}

.bc-scroll-context > .bc-navbar:nth-child(3) {
  z-index: 3;
}