/* ==========================================================================
  Additions to bootstrap/type
   ========================================================================== */

/* Colored text
   ========================================================================== */

.text-success {
  color: $green;
}

.text-danger {
  color: $red;
}

/* Muted text
   ========================================================================== */

.text-muted a {
  color: $text-muted;
  text-decoration: none;
  border-bottom: 1px solid rgba($text-muted,0.33);
  &:hover {
    color: $blue;
    border-color: $blue;
  }
}

/* Truncate
   ========================================================================== */

.truncated,
.truncated * {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
