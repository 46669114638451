/* SUGGESTIONBOX OVERRIDE */
.rich-sb-common-container {
    position: absolute;
    text-align: left;
    background-color: inherit;
}
.rich-sb-ext-decor-1 {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
}
.rich-sb-ext-decor-2 {
	border-radius: 5px;
    border: 1px solid #DDDDDD;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
}
.rich-sb-ext-decor-3 {
    height: 100%;
    width: 100%;
}
.rich-sb-overflow {
    overflow: auto;
}
.rich-sb-int-decor-table {
    width: 100%;
}
.rich-sb-int-sel {
    cursor: default;
    background-position: left top;
    background-repeat: repeat-x;
}
.rich-sb-cell-padding {
    padding: 1px 3px 1px 5px;
}
.rich-sb-int {
	cursor: pointer;
    font-size: 14px;
    color: #000;
    font-family: "Source Sans Pro", sans-serif;
}
.rich-sb-int-sel {
    background-color: #0080B3;
    font-size: 14px;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif;
}