/* ==========================================================================
  c-util
  Utilities to make containers scrollable, max height etc.
   ========================================================================== */

.c-util-maximize-height {
  height: 100%;
}

.c-util-disable-scroll {
  overflow: hidden;
}

.c-util-is-block {
  display: block;
}

.c-util-is-inline-block {
  display: inline-block;
}

.c-util-no-wrap {
  white-space: nowrap;
}

.c-util-is-bold {
	font-weight: bold;
}

.c-util-no-opacity {
  opacity: 1;
}

.c-util-relative {
  position: relative;
}

.c-util-is-scrollable {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: $screen-sm) {

  .c-util-is-scrollable-at-sm {
    overflow: scroll;
  }

}

/* Additional utilities
   ========================================================================== */

.c-util-margin-5 {
  margin: 5px;
}

.c-util-min-width-100 {
	min-width: 100px;
}

.c-util-min-width-50 {
	min-width: 50px;
}

.c-util-padding-bottom-10 {
	padding-bottom: 10px;
}

.c-util-padding-top-10 {
	padding-top: 10px;
}

/* Spacing  reset
   ========================================================================== */

.m0 {
  margin: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.p0 {
  padding: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}