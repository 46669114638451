/* Planning box
   p-box
   ========================================================================== */

.p-box {
  background: #FFF;
  border-radius: 2px;
  min-height: 30px;
  font-size: 13px;
  border: 1px solid #DDD;
  display: block;
  color: rgba($gray-dark,0.8);
  text-decoration: none;
  // Unselectable
  user-select: none !important;
}

.p-box-selected {
  box-shadow: 0 0 0 1px darken(#C4EEED, 22%), 0 0 0 3px darken(#C4EEED, 8%);
  position: relative;
  z-index: 10;
}

.p-box-inner {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  span:last-child {
    opacity: 0.5;
  }
  span:only-child,
  span:first-child {
    opacity: 1;
  }
}

.p-box:hover {
  background: #F0F0F0;
  cursor: pointer;
  text-decoration: none;
  color: rgba($gray-dark,0.8);
}

.p-box-unplanned {
  border: 1px dashed #DDD;
}

.p-box-planned {
  border: 1px solid darken(#C4EEED, 5%);
  background: #C4EEED;
}

.p-box-planned:hover {
  background: lighten(#C4EEED, 10%);
}

.p-box-stretch-hours-2 { height: 2*$hour-height; }
.p-box-stretch-hours-3 { height: 3*$hour-height; }
.p-box-stretch-hours-4 { height: 4*$hour-height; }
.p-box-stretch-hours-5 { height: 5*$hour-height; }
.p-box-stretch-hours-6 { height: 6*$hour-height; }
.p-box-stretch-hours-7 { height: 7*$hour-height; }
.p-box-stretch-hours-8 { height: 8*$hour-height; }
.p-box-stretch-hours-9 { height: 9*$hour-height; }
.p-box-stretch-hours-10 { height: 10*$hour-height; }
.p-box-stretch-hours-11 { height: 11*$hour-height; }
.p-box-stretch-hours-12 { height: 12*$hour-height; }
.p-box-stretch-hours-13 { height: 13*$hour-height; }
.p-box-stretch-hours-14 { height: 14*$hour-height; }
.p-box-stretch-hours-15 { height: 15*$hour-height; }
.p-box-stretch-hours-16 { height: 16*$hour-height; }
.p-box-stretch-hours-17 { height: 17*$hour-height; }
.p-box-stretch-hours-18 { height: 18*$hour-height; }
.p-box-stretch-hours-19 { height: 19*$hour-height; }
.p-box-stretch-hours-20 { height: 20*$hour-height; }
.p-box-stretch-hours-22 { height: 22*$hour-height; }
.p-box-stretch-hours-23 { height: 23*$hour-height; }
.p-box-stretch-hours-24 { height: 24*$hour-height; }
