/* ==========================================================================
  External link
  Wikipedia style icon next to link to denote an external reference
   ========================================================================== */

.c-external-link {
  padding: 1px;
  position: relative;
  left: 1px;
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: $gray-lightest;
    border-radius: 1px;
    transition: 0.18s background-color ease-in-out;
  }
  &:after {
    background: url(../images/external-link.svg) no-repeat right center;
    content: " ";
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
  }
}

.panel-title .c-external-link {
  padding: 4px 6px;
  position: relative;
  left: -6px;
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover,
  &:active,
  &:focus {
    background-color: $gray-light;
    border-radius: 2px;
    transition: 0.18s background-color ease-in-out;
  }
}
