$br-svg-icon-class-prefix: o-svg-icon;
/* Project settings
   ========================================================================== */

@import "settings/colors";
@import "settings/variables";
// @import "settings/icon-font";

html {
  height: 100%;
}

/* Bootstrap
   ========================================================================== */

// Core variables and mixins
@import "vendor/bootstrap/variables";
@import "vendor/bootstrap/mixins";

// Bootstrap

// Reset and dependencies
@import "vendor/bootstrap/normalize";
@import "vendor/bootstrap/print";
@import "vendor/bootstrap/glyphicons";

// Core CSS
@import "vendor/bootstrap/scaffolding";
@import "vendor/bootstrap/type";
@import "vendor/bootstrap/code";
@import "vendor/bootstrap/grid";
@import "vendor/bootstrap/tables";
@import "vendor/bootstrap/forms";
@import "vendor/bootstrap/buttons";

// Components
@import "vendor/bootstrap/component-animations";
@import "vendor/bootstrap/dropdowns";
@import "vendor/bootstrap/button-groups";
@import "vendor/bootstrap/input-groups";
@import "vendor/bootstrap/navs";
@import "vendor/bootstrap/pagination";
@import "vendor/bootstrap/badges";
@import "vendor/bootstrap/thumbnails";
@import "vendor/bootstrap/alerts";
@import "vendor/bootstrap/progress-bars";
@import "vendor/bootstrap/list-group";
@import "vendor/bootstrap/panels";
@import "vendor/bootstrap/responsive-embed";
@import "vendor/bootstrap/wells";
@import "vendor/bootstrap/close";

// Components w/ JavaScript
@import "vendor/bootstrap/modals";
@import "vendor/bootstrap/tooltip";
@import "vendor/bootstrap/popovers";

// Utility classes
@import "vendor/bootstrap/utilities";
@import "vendor/bootstrap/responsive-utilities";

/* Additions to Bootstrap components
   ========================================================================== */

@import "bootstrap-additions/tables";
@import "bootstrap-additions/dropdowns";
@import "bootstrap-additions/buttons";
@import "bootstrap-additions/forms";
@import "bootstrap-additions/panels";
@import "bootstrap-additions/modals";
@import "bootstrap-additions/alerts";
@import "bootstrap-additions/grid";
@import "bootstrap-additions/tables";
@import "bootstrap-additions/type";
@import "bootstrap-additions/popovers";
@import "bootstrap-additions/wells";
@import "bootstrap-additions/mobile";

/* Third-party CSS
   ========================================================================== */

@import "vendor/select2/src/scss/core.scss";
@import "vendor/select2-results.scss";
@import "vendor/select2-bootstrap-theme/src/select2-bootstrap.scss";
@import "vendor/c-select2-overrides";
@import "vendor/summernote/summernote";
@import "vendor/pikaday";

@import "vendor/rich/rich-datascr";
@import "vendor/rich/rich-extdt";
@import "vendor/rich/rich-file-upload";
@import "vendor/rich/rich-progress-bar";
@import "vendor/rich/rich-sb";

@import "vendor/c3";

/* Custom components (based on Bootstrap variables)
   ========================================================================== */

@import "bootstrap-custom/bc-bg";
@import "bootstrap-custom/bc-bordered-list";
@import "bootstrap-custom/bc-block-list";
@import "bootstrap-custom/bc-document-preview";
@import "bootstrap-custom/bc-form-flex";
@import "bootstrap-custom/bc-form-horizontal";
@import "bootstrap-custom/bc-label";
@import "bootstrap-custom/bc-color-label";
@import "bootstrap-custom/bc-min-width";
@import "bootstrap-custom/bc-navbar";
@import "bootstrap-custom/bc-scroll-context";
@import "bootstrap-custom/bc-navbar-nav";
@import "bootstrap-custom/bc-toolbar";
@import "bootstrap-custom/bc-navbar-brand";
@import "bootstrap-custom/bc-password-strength";
@import "bootstrap-custom/bc-spinner";
@import "bootstrap-custom/bc-table-sortable";
@import "bootstrap-custom/bc-top-controls";
@import "bootstrap-custom/bc-recent-items";
@import "bootstrap-custom/bc-progress-bar";
@import "bootstrap-custom/bc-task-popover";

/* Objects
   ========================================================================== */

@import "custom/c-flex"; // Should be refactored to object

/* Custom components (entirely custom components)
   ========================================================================== */

@import "custom/c-404";
@import "custom/c-avatar";
@import "custom/c-brand-logo";
@import "custom/c-content";
@import "custom/c-external-link";
@import "custom/c-flex";
@import "custom/c-icon-image";
@import "custom/c-input-with-icon";
@import "custom/c-logo-mobile";
@import "custom/c-p-box-holder";
@import "custom/c-p-box";
@import "custom/c-p-drag-box";
@import "custom/c-p-ip"; // Individual planning
@import "custom/c-p-res";
@import "custom/c-p-split-dropzone";
@import "custom/c-p-table";
@import "custom/c-planner-structure";
@import "custom/c-side-menu";
@import "custom/c-spacer";
@import "custom/c-util";
@import "custom/c-utility-flex";
@import "custom/c-width-limiter";
@import "custom/c-input-with-icon";
@import "custom/c-404";
@import "custom/c-external-link";
@import "custom/c-video-wrapper";
@import "custom/inline-help";
@import "custom/c-logo-mobile";
@import "custom/signature-pad";
@import "custom/c-logo-mobile";
@import "custom/c-flex";
@import "custom/c-side-panel";
@import "custom/c-main-area";

/* Additional CSS
   Not sure what to do with this remaining CSS
   ========================================================================== */

.errors {
  color: #DD5B5B;
  font-weight: bold;
}

/* CSS to fix layout in implementation
   Workaround for not being able to use bc-scroll-context logic within JSF
   ========================================================================== */

.deskdrive-implementation {
  .bc-scroll-context-content-layout-default {
    padding: 0;
  }

  /* fixed subtitle bar */
  .sub-title-bar {
    position: fixed;
    top: 82px;
    z-index: 1;
    height: 50px;
  }

  .under-sub-title-bar {
    position: relative;
    top: 50px;
    margin-bottom: 50px;
    padding: 24px 0;
  }

  /* business objects override */
  .c-side-menu-item-icon-container .c-icon-business-object {
    top: 10px;
  }

}
