/* ==========================================================================
   Flexbox object
   ========================================================================== */

/* Settings
   ========================================================================== */

$o-flex-columns: (2, 3, 4, 5, 6) !default;

$o-flex-breakpoints: (
  bp1: 960/16*1em,
  bp2: 1280/16*1em
  ) !default;

$o-flex-gutter-width: 1.6rem !default;

/* Basic containers
   ========================================================================== */

.o-flex {
  display: flex;
}

.o-flex--vertical {
  flex-direction: column;
}

.o-flex--vertical-center {
  align-items: center;
}

.o-flex--horizontal-center {
  justify-content: center;
}

.o-flex--center {
  justify-content: center;
  align-items: center;
}

.o-flex--justify-between {
  justify-content: space-between;
}

.o-flex--spaced {
  *:not(:last-child) {
   margin-right: $o-flex-gutter-width;
  }
}

/* Flexbox item
   ========================================================================== */

.o-flex__item {
  flex: 1;
}

/* Flex Columns
   ========================================================================== */

// Loop through the different columns
@each $column in $o-flex-columns {
   // Generate the grid classes
   @each $denominator, $numerator in $column {
       // Begin creating a numberator for our fraction up until we hit the
       // denominator.
       @for $numerator from 1 through $denominator {
           // Build a class in the format `.u-4-of-6`
           .o-flex-#{$numerator}-of-#{$denominator} {
              flex-basis: ($numerator / $denominator)*100%;
           }
       }
   }
}

// Loop through the different breakpoints
@each $breakpoint, $value in $o-flex-breakpoints {
   @media (min-width: $value) {
       // Loop through the different columns
       @each $column in $o-flex-columns {
           // Generate the grid classes
           @each $denominator, $numerator in $column {
               // Begin creating a numberator for our fraction up until we hit the
               // denominator.
               @for $numerator from 1 through $denominator {
                   // Build a class in the format `.u-4-of-6`
                   .o-flex-#{$numerator}-of-#{$denominator}-#{$breakpoint} {
                      flex-basis: ($numerator / $denominator)*100%;
                   }
               }
           }
       }
   }
}
