/* ==========================================================================
  .c-input-with-icon
   ========================================================================== */

.c-input-with-icon {
  position: relative;

  input,
  input.form-control {
    padding-left: 35px;
  }

  .glyphicon {
    position: absolute;
    top: 50%;
    margin-top: -9px;
    color: rgba(#000, 0.4);
    left: 10px;
    font-size: 18px;
  }
}

.c-input-with-icon-small {
  input,
  input.form-control {
    padding-left: 30px;
  }

  .glyphicon {
    font-size: 14px;
    margin-top: -7px;
  }
}
