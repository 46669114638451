@font-face {
  font-family: "summernote";
  font-style: normal;
  font-weight: normal;
  src: url("/fonts/summernote.eot?ad8d7e2d177d2473aecd9b35d16211fb");
  src: url("/fonts/summernote.eot?#iefix") format("embedded-opentype"), url("/fonts/summernote.woff?ad8d7e2d177d2473aecd9b35d16211fb") format("woff"), url("/fonts/summernote.ttf?ad8d7e2d177d2473aecd9b35d16211fb") format("truetype")
}

[class^="note-icon-"]:before, [class*=" note-icon-"]:before {
  display: inline-block;
  font: normal normal normal 14px summernote;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  text-decoration: inherit;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  speak: none;
  -moz-osx-font-smoothing: grayscale
}

.note-icon-align-center:before {
  content: "\f101"
}

.note-icon-align-indent:before {
  content: "\f102"
}

.note-icon-align-justify:before {
  content: "\f103"
}

.note-icon-align-left:before {
  content: "\f104"
}

.note-icon-align-outdent:before {
  content: "\f105"
}

.note-icon-align-right:before {
  content: "\f106"
}

.note-icon-align:before {
  content: "\f107"
}

.note-icon-arrows-alt:before {
  content: "\f108"
}

.note-icon-bold:before {
  content: "\f109"
}

.note-icon-caret:before {
  content: "\f10a"
}

.note-icon-chain-broken:before {
  content: "\f10b"
}

.note-icon-circle:before {
  content: "\f10c"
}

.note-icon-close:before {
  content: "\f10d"
}

.note-icon-code:before {
  content: "\f10e"
}

.note-icon-eraser:before {
  content: "\f10f"
}

.note-icon-font:before {
  content: "\f110"
}

.note-icon-frame:before {
  content: "\f111"
}

.note-icon-italic:before {
  content: "\f112"
}

.note-icon-link:before {
  content: "\f113"
}

.note-icon-magic:before {
  content: "\f114"
}

.note-icon-menu-check:before {
  content: "\f115"
}

.note-icon-minus:before {
  content: "\f116"
}

.note-icon-orderedlist:before {
  content: "\f117"
}

.note-icon-pencil:before {
  content: "\f118"
}

.note-icon-picture:before {
  content: "\f119"
}

.note-icon-question:before {
  content: "\f11a"
}

.note-icon-redo:before {
  content: "\f11b"
}

.note-icon-special-character:before {
  content: "\f11c"
}

.note-icon-square:before {
  content: "\f11d"
}

.note-icon-strikethrough:before {
  content: "\f11e"
}

.note-icon-subscript:before {
  content: "\f11f"
}

.note-icon-summernote:before {
  content: "\f120"
}

.note-icon-superscript:before {
  content: "\f121"
}

.note-icon-table:before {
  content: "\f122"
}

.note-icon-text-height:before {
  content: "\f123"
}

.note-icon-trash:before {
  content: "\f124"
}

.note-icon-underline:before {
  content: "\f125"
}

.note-icon-undo:before {
  content: "\f126"
}

.note-icon-unorderedlist:before {
  content: "\f127"
}

.note-icon-video:before {
  content: "\f128"
}

.note-editor {
  position: relative
}

.note-editor .note-dropzone {
  position: absolute;
  z-index: 100;
  display: none;
  color: #87cefa;
  background-color: white;
  opacity: .95;
  pointer-event: none
}

.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle
}

.note-editor .note-dropzone.hover {
  color: #098ddf
}

.note-editor.dragover .note-dropzone {
  display: table
}

.note-editor .note-editing-area {
  position: relative
}

.note-editor .note-editing-area .note-editable {
  outline: 0
}

.note-editor .note-editing-area .note-editable sup {
  vertical-align: super
}

.note-editor .note-editing-area .note-editable sub {
  vertical-align: sub
}

.note-editor.note-frame {
  border: 1px solid #a9a9a9
}

.note-editor.note-frame.codeview .note-editing-area .note-editable {
  display: none
}

.note-editor.note-frame.codeview .note-editing-area .note-codable {
  display: block
}

.note-editor.note-frame .note-editing-area {
  overflow: hidden
}

.note-editor.note-frame .note-editing-area .note-editable {
  padding: 10px;
  overflow: auto;
  color: #000;
  background-color: #fff
}

.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
  background-color: #e5e5e5
}

.note-editor.note-frame .note-editing-area .note-codable {
  display: none;
  width: 100%;
  padding: 10px;
  margin-bottom: 0;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  color: #ccc;
  background-color: #222;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  resize: none
}

.note-editor.note-frame.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%
}

.note-editor.note-frame.fullscreen .note-editable {
  background-color: white
}

.note-editor.note-frame.fullscreen .note-resizebar {
  display: none
}

.note-editor.note-frame .note-statusbar {
  background-color: #f5f5f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.note-editor.note-frame .note-statusbar .note-resizebar {
  width: 100%;
  height: 8px;
  padding-top: 1px;
  cursor: ns-resize
}

.note-editor.note-frame .note-statusbar .note-resizebar .note-icon-bar {
  width: 20px;
  margin: 1px auto;
  border-top: 1px solid #a9a9a9
}

.note-editor.note-frame .note-placeholder {
  padding: 10px
}

.note-popover.popover {
  max-width: none
}

.note-popover.popover .popover-content a {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle
}

.note-popover.popover .arrow {
  left: 20px !important
}

.note-popover .popover-content, .panel-heading.note-toolbar {
  padding: 0 0 5px 5px;
  margin: 0
}

.note-popover .popover-content > .btn-group, .panel-heading.note-toolbar > .btn-group {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0
}

.note-popover .popover-content .btn-group .note-table, .panel-heading.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 5px
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}

.note-popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted, .panel-heading.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat
}

.note-popover .popover-content .note-style h1, .panel-heading.note-toolbar .note-style h1, .note-popover .popover-content .note-style h2, .panel-heading.note-toolbar .note-style h2, .note-popover .popover-content .note-style h3, .panel-heading.note-toolbar .note-style h3, .note-popover .popover-content .note-style h4, .panel-heading.note-toolbar .note-style h4, .note-popover .popover-content .note-style h5, .panel-heading.note-toolbar .note-style h5, .note-popover .popover-content .note-style h6, .panel-heading.note-toolbar .note-style h6, .note-popover .popover-content .note-style blockquote, .panel-heading.note-toolbar .note-style blockquote {
  margin: 0
}

.note-popover .popover-content .note-color .dropdown-toggle, .panel-heading.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px
}

.note-popover .popover-content .note-color .dropdown-menu, .panel-heading.note-toolbar .note-color .dropdown-menu {
  min-width: 340px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group:first-child, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eee
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  width: 100%;
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px
}

.note-popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover, .panel-heading.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eee
}

.note-popover .popover-content .note-para .dropdown-menu, .panel-heading.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px
}

.note-popover .popover-content .note-para .dropdown-menu > div:first-child, .panel-heading.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px
}

.note-popover .popover-content .dropdown-menu, .panel-heading.note-toolbar .dropdown-menu {
  min-width: 90px
}

.note-popover .popover-content .dropdown-menu.right, .panel-heading.note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto
}

.note-popover .popover-content .dropdown-menu.right::before, .panel-heading.note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important
}

.note-popover .popover-content .dropdown-menu.right::after, .panel-heading.note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important
}

.note-popover .popover-content .dropdown-menu.note-check li a i, .panel-heading.note-toolbar .dropdown-menu.note-check li a i {
  color: deepskyblue;
  visibility: hidden
}

.note-popover .popover-content .dropdown-menu.note-check li a.checked i, .panel-heading.note-toolbar .dropdown-menu.note-check li a.checked i {
  visibility: visible
}

.note-popover .popover-content .note-fontsize-10, .panel-heading.note-toolbar .note-fontsize-10 {
  font-size: 10px
}

.note-popover .popover-content .note-color-palette, .panel-heading.note-toolbar .note-color-palette {
  line-height: 1
}

.note-popover .popover-content .note-color-palette div .note-color-btn, .panel-heading.note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid #fff
}

.note-popover .popover-content .note-color-palette div .note-color-btn:hover, .panel-heading.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000
}

.note-dialog > div {
  display: none
}

.note-dialog .form-group {
  margin-right: 0;
  margin-left: 0
}

.note-dialog .note-modal-form {
  margin: 0
}

.note-dialog .note-image-dialog .note-dropzone {
  min-height: 100px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 4;
  color: lightgray;
  text-align: center;
  border: 4px dashed lightgray
}

@-moz-document url-prefix() {
  .note-image-input {
    height: auto
  }
}

.note-placeholder {
  position: absolute;
  display: none;
  color: gray
}

.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid black
}

.note-handle .note-control-selection > div {
  position: absolute
}

.note-handle .note-control-selection .note-control-selection-bg {
  width: 100%;
  height: 100%;
  background-color: black;
  -webkit-opacity: .3;
  -khtml-opacity: .3;
  -moz-opacity: .3;
  opacity: .3;
  -ms-filter: alpha(opacity=30);
  filter: alpha(opacity=30)
}

.note-handle .note-control-selection .note-control-handle {
  width: 7px;
  height: 7px;
  border: 1px solid black
}

.note-handle .note-control-selection .note-control-holder {
  width: 7px;
  height: 7px;
  border: 1px solid black
}

.note-handle .note-control-selection .note-control-sizing {
  width: 7px;
  height: 7px;
  background-color: white;
  border: 1px solid black
}

.note-handle .note-control-selection .note-control-nw {
  top: -5px;
  left: -5px;
  border-right: 0;
  border-bottom: 0
}

.note-handle .note-control-selection .note-control-ne {
  top: -5px;
  right: -5px;
  border-bottom: 0;
  border-left: none
}

.note-handle .note-control-selection .note-control-sw {
  bottom: -5px;
  left: -5px;
  border-top: 0;
  border-right: 0
}

.note-handle .note-control-selection .note-control-se {
  right: -5px;
  bottom: -5px;
  cursor: se-resize
}

.note-handle .note-control-selection .note-control-se.note-control-holder {
  cursor: default;
  border-top: 0;
  border-left: none
}

.note-handle .note-control-selection .note-control-selection-info {
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  color: white;
  background-color: black;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-opacity: .7;
  -khtml-opacity: .7;
  -moz-opacity: .7;
  opacity: .7;
  -ms-filter: alpha(opacity=70);
  filter: alpha(opacity=70)
}

.note-hint-popover {
  min-width: 100px;
  padding: 2px
}

.note-hint-popover .popover-content {
  max-height: 150px;
  padding: 3px;
  overflow: auto
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item {
  display: block !important;
  padding: 3px
}

.note-hint-popover .popover-content .note-hint-group .note-hint-item.active, .note-hint-popover .popover-content .note-hint-group .note-hint-item:hover {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.4;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: #428bca;
  outline: 0
}
